::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-button {
  width: 10px;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
